import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import ApiService from "@/core/services/ApiService";
import InlineSVG from "@/components/presets/GeneralElements/InlineSVG.vue";
import { initInlineSvg } from "@/core/plugins/inline-svg";

import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app = createApp(App);

const pinia = createPinia();

app.use(pinia);

app.use(router);

ApiService.init(app);
app.use(ElementPlus, { size: "large", zIndex: 3000 });
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component);
// }
initInlineSvg(app);

app.component("InlineSVG", InlineSVG);

app.mount("#app");
