const AccessionRoutes = [
  {
    path: "/accessions",
    name: "AccessionPage",
    component: () => import("@/views/accessions/AccessionPage.vue"),
  },
  {
    path: "/accessions/:id",
    name: "accession-view",
    component: () => import("@/views/accessions/AccessionView.vue"),
  },
];
export default AccessionRoutes;
