import ApiService from "@/core/services/ApiService";
import JwtService, {
  saveRememberToken,
  saveUser,
} from "@/core/services/JwtService";
import { defineStore } from "pinia";
import IUserAuth from "@/store/interfaces/IUserAuth";
import { Actions } from "@/store/enums/StoreEnums";

export interface UserAuthInfo {
  errors: unknown;
  user: IUserAuth;
  isAuthenticated: boolean;
}

export const AuthModuleStore = defineStore("UserAuthInfo", {
  state: () => ({
    errors: {},
    user: {} as IUserAuth,
    isAuthenticated: !!JwtService.getToken(),
  }),
  getters: {
    /**
     * Get current user object
     * @returns User
     */
    currentUser(): IUserAuth {
      return this.user;
    },

    /**
     * Get current user object
     * @returns User
     */
    userRole() {
      return this.user.role;
    },
    /**
     * Get current user organization
     * @returns User
     */

    /**
     * Verify user authentication
     * @returns boolean
     */
    isUserAuthenticated(): boolean {
      return this.isAuthenticated;
    },

    /**
     * Get authentification errors
     * @returns array
     */
    getErrors() {
      return this.errors;
    },
  },
  actions: {
    [Actions.SET_ERROR](error) {
      this.errors = { ...error, status: "failed" };
    },

    [Actions.SET_AUTH](user) {
      this.isAuthenticated = true;
      this.user = user;
      this.errors = {};
      JwtService.saveToken(user.access_token);
    },

    [Actions.SET_USER](user) {
      this.user = user;
    },

    [Actions.PURGE_AUTH]() {
      this.isAuthenticated = false;
      this.user = {} as IUserAuth;
      this.errors = [];
      JwtService.destroyToken();
    },

    [Actions.RESET_PASSWORD](payload) {
      ApiService.clearHeader();
      return ApiService.post("v1/reset-password", payload.credentials)
        .then(({ data }) => {
          if (payload[Actions.SET_AUTH]) this[Actions.SET_AUTH](data);
          return data;
        })
        .catch(({ response }) => {
          this[Actions.SET_ERROR](response.data);
          return Promise.reject();
        });
    },

    [Actions.LOGIN](payload) {
      ApiService.clearHeader();
      return ApiService.post("v1/login ", payload.credentials)
        .then(({ data }) => {
          if (payload[Actions.SET_AUTH]) this[Actions.SET_AUTH](data);
          saveRememberToken(data.access_token);
          saveUser(data);
          return data;
        })
        .catch(({ response }) => {
          this[Actions.SET_ERROR](response.data);
          return Promise.reject();
        });
    },

    [Actions.LOGOUT]() {
      this[Actions.PURGE_AUTH]();
    },
  },
});
