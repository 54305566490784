const Actions = {
  ACCESSIONS: {
    LIST: "ACCESSIONS_LIST",
  },
  SET_ACCESSIONS: {
    LIST: "SET_ACCESSIONS_LIST",
  },
  USERS: {
    LIST: "USERS_LIST",
  },
  SET_USERS: {
    LIST: "SET_USERS_LIST",
  },
  REPORTS: {
    LIST: "REPORTS_LIST",
  },
  SET_REPORTS: {
    LIST: "SET_REPORTS_LIST",
  },
  SET_ERROR: "setError",
  SET_AUTH: "setAuth",
  SET_USER: "setUser",
  PURGE_AUTH: "logOut",
  LOGIN: "login",
  LOGOUT: "logout",
  VERIFY_AUTH: "verifyAuth",
  VERIFY_BROADCAST_AUTH: "verifyBroadcastAuth",
  FORGOT_PASSWORD: "forgotPassword",
  RESET_PASSWORD: "resetPassword",
  SET_BREADCRUMB_CHANGE: "breadCrumbChange",
  SET_BREADCRUMB_ACTION: "breadCrumbAction",
};

export { Actions };
