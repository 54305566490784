const ReportRoutes = [
  {
    path: "/accessions/:accessionId/reports/:reportId",
    name: "report.show",
    component: () => import("@/views/reports/ReportView.vue"),
  },
  {
    path: "/accessions/:accessionId/report",
    name: "report.create",
    component: () => import("@/views/reports/ReportCreate.vue"),
  },
  {
    path: "/accessions/:accessionId/reports/:reportId/edit",
    name: "report.edit",
    component: () => import("@/views/reports/ReportEdit.vue"),
  },
  {
    path: "/accessions/:accessionId/reports/:reportId/supplementary_report",
    name: "report.supplementary",
    component: () => import("@/views/reports/ReportCreateSupplementary.vue"),
  },
];
export default ReportRoutes;
