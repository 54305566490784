const UserRoutes = [
  {
    path: "/users",
    name: "UserPage",
    component: () => import("@/views/users/UserPage.vue"),
  },
  {
    path: "/users/:id",
    name: "user-view",
    component: () => import("@/views/users/UserView.vue"),
  },
  {
    path: "/users/:id/password-change",
    name: "password-change",
    component: () => import("@/components/users/PasswordReset.vue"),
  },
];
export default UserRoutes;
