const PatientRoutes = [
  {
    path: "/patients",
    name: "patients",
    component: () => import("@/views/patients/PatientPage.vue"),
  },
  {
    path: "/patients/:id",
    name: "patients-view",
    component: () => import("@/views/patients/PatientView.vue"),
    children: [
      {
        path: "administration",
        name: "patients-view-administration",
        component: () => import("@/views/patients/Administration.vue"),
      },
      {
        path: "/patients/:id/accessions",
        name: "patients-view-accessions",
        component: () => import("@/views/patients/PatientAccessions.vue"),
      },
    ],
  },
];

export default PatientRoutes;
