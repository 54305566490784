import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { AuthModuleStore } from "@/store/modules/AuthModule";
import { computed, ref } from "vue";
import accessionRoutes from "@/router/AccessionRoutes";
import patientRoutes from "@/router/PatientRoutes";
import reportRoutes from "@/router/ReportRoutes";
import { jwtDecode } from "jwt-decode";

import { Actions } from "@/store/enums/StoreEnums";
import JwtService, { saveToken } from "@/core/services/JwtService";
import userRoutes from "@/router/UserRoutes";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "SignIn",
    component: () => import("@/views/login/SignIn.vue"),
  },
  ...accessionRoutes,
  ...patientRoutes,
  ...reportRoutes,
  ...userRoutes,
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const isUserAuthenticated = computed(() => authUserStore.isUserAuthenticated);
  const authUserStore = AuthModuleStore();

  if (localStorage.id_token) {
    const decoded = jwtDecode(localStorage.id_token);
    if (decoded.exp < Date.now() / 1000 && to.name !== "SignIn") {
      return { name: "SignIn" };
    }
  }

  if (!isUserAuthenticated.value && to.name !== "SignIn") {
    return { name: "SignIn" };
  }
});

export default router;
